/* eslint-disable react/jsx-props-no-spreading */
import { getComponentFromName } from '@folklore/utils';
import PropTypes from 'prop-types';
import React from 'react';

import * as iconComponents from './index';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function MapIcon({ icon, ...props }) {
    const IconComponent = icon !== null ? getComponentFromName(iconComponents, icon) : null;
    return IconComponent !== null ? <IconComponent {...props} /> : null;
}

MapIcon.propTypes = propTypes;
MapIcon.defaultProps = defaultProps;

export default MapIcon;
